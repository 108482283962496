import Image1 from "../Assets/sample2.webp"
import Image2 from "../Assets/sample.webp"
import Image3 from "../Assets/sample0.webp"

export const ShopProducts2021 = [
  {
    id: 1,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image1,
    price: "299.95",
  },
  {
    id: 2,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image1,
    price: "299.95",
  },
  {
    id: 3,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image2,
    price: "299.95",
  },
  {
    id: 4,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image3,
    price: "299.95",
  },
  {
    id: 5,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image3,
    price: "299.95",
  },
  {
    id: 6,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image1,
    price: "299.95",
  },
  {
    id: 7,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image3,
    price: "299.95",
  },
  {
    id: 8,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image1,
    price: "299.95",
  },
  {
    id: 9,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image2,
    price: "299.95",
  },
  {
    id: 10,
    title: "2021 State Agency Directory Digital Edition",
    productImage: Image2,
    price: "299.95",
  },
]
